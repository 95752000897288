export function useGridSize() {

  const getGridSize = (value, mode = 'page') => {
    if (mode === 'sidebar') {
      const totalGrids = 12
      const convertToSidebarGrid = value ? Number(value) * 2 : value
      return convertToSidebarGrid > totalGrids ? totalGrids : convertToSidebarGrid
    }
    return value
  }
  return { getGridSize }
}
