var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[(_vm.restrictAddSecurityMethodToMultipleCity)?_c('b-form-row',[_c('b-col',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.DISPLAY_MESSAGES.MULTIPLE_CITIES_ADD_SECURITY_METHOD_ERROR))])],1)],1):[(_vm.showLoader)?_c('LoaderIcon'):_c('b-form',{attrs:{"id":"security-method-form","name":"security-method-form","autocomplete":"off","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":_vm.getGridSize(7, _vm.mode),"md":_vm.getGridSize(5, _vm.mode),"lg":_vm.getGridSize(3, _vm.mode)}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Security method name")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                    'is-invalid': !_vm.securityMethodForm.methodName && _vm.formSubmitted,
                  },attrs:{"id":"security-method-name","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityMethodForm.methodName),callback:function ($$v) {_vm.$set(_vm.securityMethodForm, "methodName", $$v)},expression:"securityMethodForm.methodName"}}),_c('label',{attrs:{"for":"security-method-name"}},[_vm._v("Security method name")]),(!_vm.securityMethodForm.methodName && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Security method name required.")]):_vm._e()],1)])],1),_c('b-col',{class:{ 'pt-md-4 mt-md-3': _vm.mode === 'page' },attrs:{"cols":"12","sm":_vm.getGridSize(6, _vm.mode),"md":_vm.getGridSize(6, _vm.mode),"lg":_vm.getGridSize(4, _vm.mode)}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"is-default-security-method","name":"is-default-security-method","disabled":_vm.isDefaultMethodExist},model:{value:(_vm.securityMethodForm.isDefault),callback:function ($$v) {_vm.$set(_vm.securityMethodForm, "isDefault", $$v)},expression:"securityMethodForm.isDefault"}},[_vm._v(" Set as default security method for all API calls ")])],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":_vm.getGridSize(7, _vm.mode),"md":_vm.getGridSize(5, _vm.mode),"lg":_vm.getGridSize(3, _vm.mode)}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("HttpClientType")]),_c('div',{staticClass:"floating-select-field"},[_c('b-form-select',{staticClass:"form-control",class:{
                    'is-value-exist': _vm.securityMethodForm.httpClientType != null,
                    'is-invalid': !_vm.securityMethodForm.httpClientType && _vm.formSubmitted,
                  },attrs:{"id":"http-client-type"},on:{"input":function($event){_vm.securityMethodForm.httpClientType = $event},"change":_vm.onChangeHttpClientType},model:{value:(_vm.securityMethodForm.httpClientType),callback:function ($$v) {_vm.$set(_vm.securityMethodForm, "httpClientType", $$v)},expression:"securityMethodForm.httpClientType"}},_vm._l((_vm.httpClientTypeOptions),function(type,i){return _c('b-form-select-option',{key:i,attrs:{"value":type.value}},[_vm._v(_vm._s(type.title))])}),1),_c('label',{attrs:{"for":"http-client-type"}},[_vm._v("HttpClientType")]),(!_vm.securityMethodForm.httpClientType && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("HttpClientType required.")]):_vm._e()],1)])],1)],1),(_vm.securityMethodForm.httpClientType === _vm.httpClientTypeEnum.SIMPLE_AUTH)?[_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":_vm.getGridSize(10, _vm.mode),"md":_vm.getGridSize(8, _vm.mode),"lg":_vm.getGridSize(6, _vm.mode)}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("API key name")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                      'is-invalid': !_vm.securityMethodForm.apiKeyName && _vm.formSubmitted,
                    },attrs:{"id":"api-key-name","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityMethodForm.apiKeyName),callback:function ($$v) {_vm.$set(_vm.securityMethodForm, "apiKeyName", $$v)},expression:"securityMethodForm.apiKeyName"}}),_c('label',{attrs:{"for":"api-key-name"}},[_vm._v("API key name")]),(!_vm.securityMethodForm.apiKeyName && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("API key name required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":_vm.getGridSize(10, _vm.mode),"md":_vm.getGridSize(8, _vm.mode),"lg":_vm.getGridSize(6, _vm.mode)}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("API key value")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                      'is-invalid': !_vm.securityMethodForm.apiKeyValue && _vm.formSubmitted,
                    },attrs:{"id":"api-key-value","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityMethodForm.apiKeyValue),callback:function ($$v) {_vm.$set(_vm.securityMethodForm, "apiKeyValue", $$v)},expression:"securityMethodForm.apiKeyValue"}}),_c('label',{attrs:{"for":"api-key-value"}},[_vm._v("API key value")]),(!_vm.securityMethodForm.apiKeyValue && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("API key value required.")]):_vm._e()],1)])],1)],1)]:_vm._e(),(_vm.securityMethodForm.httpClientType === this.httpClientTypeEnum.OAUTH2)?[_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":_vm.getGridSize(10, _vm.mode),"md":_vm.getGridSize(8, _vm.mode),"lg":_vm.getGridSize(6, _vm.mode)}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Authentication URL")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                      'is-invalid':
                        (!_vm.securityMethodForm.authenticationUrl && _vm.formSubmitted) || (!_vm.validateUrl(_vm.securityMethodForm.authenticationUrl) && _vm.formSubmitted),
                    },attrs:{"id":"authentication-url","type":"url","placeholder":" ","required":""},model:{value:(_vm.securityMethodForm.authenticationUrl),callback:function ($$v) {_vm.$set(_vm.securityMethodForm, "authenticationUrl", $$v)},expression:"securityMethodForm.authenticationUrl"}}),_c('label',{attrs:{"for":"authentication-url"}},[_vm._v("Authentication URL")]),(!_vm.securityMethodForm.authenticationUrl && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Authentication URL required.")]):_vm._e(),(_vm.securityMethodForm.authenticationUrl && !_vm.validateUrl(_vm.securityMethodForm.authenticationUrl) && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Invalid url.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":_vm.getGridSize(10, _vm.mode),"md":_vm.getGridSize(8, _vm.mode),"lg":_vm.getGridSize(6, _vm.mode)}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Client ID")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                      'is-invalid': !_vm.securityMethodForm.clientId && _vm.formSubmitted,
                    },attrs:{"id":"client-id","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityMethodForm.clientId),callback:function ($$v) {_vm.$set(_vm.securityMethodForm, "clientId", $$v)},expression:"securityMethodForm.clientId"}}),_c('label',{attrs:{"for":"client-id"}},[_vm._v("Client ID")]),(!_vm.securityMethodForm.clientId && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Client ID required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":_vm.getGridSize(10, _vm.mode),"md":_vm.getGridSize(8, _vm.mode),"lg":_vm.getGridSize(6, _vm.mode)}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Client secret")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                      'is-invalid': !_vm.securityMethodForm.clientSecret && _vm.formSubmitted,
                    },attrs:{"id":"client-secret","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityMethodForm.clientSecret),callback:function ($$v) {_vm.$set(_vm.securityMethodForm, "clientSecret", $$v)},expression:"securityMethodForm.clientSecret"}}),_c('label',{attrs:{"for":"client-secret"}},[_vm._v("Client secret")]),(!_vm.securityMethodForm.clientSecret && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Client secret required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":_vm.getGridSize(10, _vm.mode),"md":_vm.getGridSize(8, _vm.mode),"lg":_vm.getGridSize(6, _vm.mode)}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Scope")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                      'is-invalid': !_vm.securityMethodForm.scope && _vm.formSubmitted,
                    },attrs:{"id":"scope","type":"text","placeholder":" ","required":""},model:{value:(_vm.securityMethodForm.scope),callback:function ($$v) {_vm.$set(_vm.securityMethodForm, "scope", $$v)},expression:"securityMethodForm.scope"}}),_c('label',{attrs:{"for":"scope"}},[_vm._v("Scope")]),(!_vm.securityMethodForm.scope && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Scope required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","sm":_vm.getGridSize(10, _vm.mode),"md":_vm.getGridSize(8, _vm.mode),"lg":_vm.getGridSize(6, _vm.mode)}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Grant type")]),_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{attrs:{"id":"grant-type","type":"text","placeholder":" ","readonly":"","required":""},model:{value:(_vm.securityMethodForm.grantType),callback:function ($$v) {_vm.$set(_vm.securityMethodForm, "grantType", $$v)},expression:"securityMethodForm.grantType"}}),_c('label',{attrs:{"for":"grant-type"}},[_vm._v("Grant type")])],1)])],1)],1)]:_vm._e(),(this.mode === 'page')?_c('b-form-row',{staticClass:"my-4"},[_c('b-col',[_c('b-button',{directives:[{name:"activeBlur",rawName:"v-activeBlur"}],attrs:{"type":"submit","id":"save-security-method-btn","variant":"primary","disabled":_vm.addEditSecurityMethodLoading}},[_vm._v("Save "),(_vm.addEditSecurityMethodLoading)?_c('b-spinner',{staticClass:"ml-2",attrs:{"label":"Spinning","small":""}}):_vm._e()],1),_c('b-button',{directives:[{name:"activeBlur",rawName:"v-activeBlur"}],staticClass:"ml-3",attrs:{"type":"button","id":"cancel-security-method-btn","variant":"outline-secondary"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v("Cancel")])],1)],1):_vm._e()],2)]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }